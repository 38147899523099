import React from 'react';
import Modal from '../../../components/Modal';
import graphic from '../../../images/graphics/modal-columb.jpg';
import img from '../../../images/city/columbus_header.jpg';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'Columbus, GA',
  cityModal: true,
  type: 'image',
  image: {
    src: img,
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      Columbus was an important manufacturing base for the Confederacy.
      Following the Civil War in the spring of 1866, the Ladies Memorial
      Association of Columbus advocated for the establishment of a Confederate
      Memorial Day which today is observed in 12 southern states. Today,
      Columbus is the third largest city in the state. Its population of nearly
      200,000 is slightly more black than white. Nearby Fort Benning, which
      supports more than 120,000 active-duty military, family members, reserve
      component soldiers, retirees, and civilian employees daily, is the city’s
      largest employer.
    </p>
    <p>
      Twenty-five percent of the county's black families live in poverty,
      compared to 8 percent of its white families. The county has a higher
      income inequality rate than the state’s average. While the median income
      for a household in Columbus is $42,600, there is a significant racial
      income inequality. Black households make an average of $31,900 while white
      households make an average of $56,300. In Columbus, we hosted focus groups
      where we heard the opinions of college-educated African Americans and
      white residents, both with mixed age and gender among the respondents.
      Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
    <img
      src={graphic}
      alt="Bar charts and map of racial distribution in Columbus, GA"
      className="Img-responsive"
    />
  </Modal>
);
